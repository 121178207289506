import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './Home'; // Maak een aparte Home-component
import ProductList from './ProductList'; // Je bestaande ProductList-component
import './App.css';

function App() {
  return (
    <Router>
      <div className="app">
        {/* Header */}
        <header className="header">
          <div className="header-container">
            <h1 className="logo">Mijn Webshop</h1>
            <nav className="nav-menu">
              <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/products">Producten</Link></li>
              </ul>
            </nav>
          </div>
        </header>

        {/* Main Content */}
        <main className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<ProductList />} />
          </Routes>
        </main>

        {/* Footer */}
        <footer className="footer">
          <div className="footer-container">
            <p>&copy; {new Date().getFullYear()} Mijn Webshop. Alle rechten voorbehouden.</p>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
