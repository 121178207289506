import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ProductList.css';

function ProductList() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filters, setFilters] = useState({
    colors: [], // Geselecteerde kleuren
    brands: [], // Geselecteerde merken
  });

  useEffect(() => {
    async function fetchProducts() {
      try {
        const response = await axios.get('https://www.wvdzonwering.nl/wvd_api/product/list?category_id=3');
        setProducts(response.data);
        setFilteredProducts(response.data); // Begin met alle producten
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    }

    fetchProducts();
  }, []);

  // Filterfunctie
  useEffect(() => {
    const filtered = products.filter((product) => {
      const matchesColor =
        filters.colors.length === 0 || filters.colors.some((color) => Array.isArray(product.kleur_lamel) && product.kleur_lamel.includes(color));
      const matchesBrand =
        filters.brands.length === 0 || filters.brands.includes(product.merk);
      return matchesColor && matchesBrand;
    });
    setFilteredProducts(filtered);
  }, [filters, products]);

  const toggleFilter = (filterType, value) => {
    setFilters((prevFilters) => {
      const currentValues = prevFilters[filterType];
      const isValueSelected = currentValues.includes(value);

      const updatedValues = isValueSelected
        ? currentValues.filter((item) => item !== value) // Verwijder de waarde
        : [...currentValues, value]; // Voeg de waarde toe

      return {
        ...prevFilters,
        [filterType]: updatedValues,
      };
    });
  };

  const uniqueColors = [...new Set(products.flatMap((product) => product.kleur_lamel || []))];
  const uniqueBrands = [...new Set(products.map((product) => product.merk))];

  return (
    <div className="product-page">
      <h1 className="page-title">Producten</h1>

    <div className="page-layout">
  {/* Filteropties */}
  <aside className="filters">
    <div className="filter-section">
      <h3>Kleur</h3>
      {uniqueColors.sort((a, b) => a.localeCompare(b)).map((color) => (
        <label key={color}>
          <input
            type="checkbox"
            value={color}
            checked={filters.colors.includes(color)}
            onChange={() => toggleFilter('colors', color)}
          />
          {color}
        </label>
      ))}
    </div>

    <div className="filter-section">
      <h3>Merk</h3>
      {uniqueBrands.map((brand) => (
        <label key={brand}>
          <input
            type="checkbox"
            value={brand}
            checked={filters.brands.includes(brand)}
            onChange={() => toggleFilter('brands', brand)}
          />
          {brand}
        </label>
      ))}
    </div>
  </aside>

  {/* Product Grid */}
  <main className="product-grid">
    {filteredProducts.map((product) => (
      <div key={product.id} className="product-card">
        <img src={product.image_url} alt={product.name} className="product-image" />
        <h2 className="product-name">{product.name}</h2>
        <p className="product-price">€{product.price}</p>
        <button className="add-to-cart">In winkelwagen</button>
      </div>
    ))}
  </main>
</div>
    </div>
  );
}

export default ProductList;
