import React from 'react';

function Home() {
  return (
    <div>
      <h1>Welkom bij Mijn Webshop</h1>
      <p>Blader door onze collectie en ontdek geweldige producten!</p>
    </div>
  );
}

export default Home;
